const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_Oc2WZsLqc',
  aws_user_pools_web_client_id: '67s1v9q5vofvs9jvh98kv0cjpv',
  aws_appsync_graphqlEndpoint: 'https://2z5ovdlxgzf6pgkfx7obxsvzom.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  oauth: {
    domain: 'dev-ridecare.auth.eu-central-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://assist.vehicleye.bosch-ridecare.com/control-center/vehicles',
    redirectSignOut: 'https://assist.vehicleye.bosch-ridecare.com/auth/login',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsmobile;
